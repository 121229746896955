import { Navigation, Pagination } from 'swiper'
import 'swiper/scss/pagination'
import { Swiper as SwiperType } from 'swiper/types'

import React, { MouseEventHandler } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import Section from '@components/Content/Section/Section'
import Wysiwyg from '@components/Layout/Wysiwyg'

import { HistoryQueryProps } from '@contracts/page'

import * as styles from './History.module.scss'

interface Props {
	data: HistoryQueryProps
}

const History = (props: Props) => {
	const [swiper, setSwiper] = React.useState<SwiperType>()

	const slideNext = () => {
		if (swiper) {
			swiper.slideNext()
		}
	}

	const slidePrev = () => {
		if (swiper) {
			swiper.slidePrev()
		}
	}

	return (
		<Section className={styles.section} containerClassName={styles.container}>
			<div className={styles.mainContent}>
				<StaticImage src="../../../images/motorfinity-logo.png" alt="" className={styles.motorfinityLogo} />

				<h2 className={styles.title}>{props.data.title}</h2>

				<Wysiwyg content={props.data.subtext} />
			</div>

			<div className={styles.slideContent}>
				<div className={styles.paginationContainer}>
					<div className={styles.pagination}></div>
				</div>

				<div className={styles.navigationContainer}>
					<div className={styles.navPrev} onClick={() => slidePrev()}></div>
					<div className={styles.navNext} onClick={() => slideNext()}></div>
				</div>

				<Swiper
					modules={[Pagination, Navigation]}
					direction="horizontal"
					pagination={{
						el: `.${styles.pagination}`,
						renderBullet: (index, className) => {
							return `<span class="${className}">${props.data.years[index].year}</span>`
						},
						dynamicBullets: true,
						dynamicMainBullets: 1,
						clickable: true,
					}}
					onSwiper={(instance: SwiperType) => {
						setSwiper(instance)
					}}
					navigation={true}
					centeredSlides={false}
					slidesPerView={1}
					loop={true}
					effect="fade"
					className={styles.slider}
					breakpoints={{
						1024: {
							direction: 'vertical',
							slidesPerView: 1,
							centeredSlides: true,
						},
					}}
				>
					{props.data.years.map((year) => {
						return (
							<SwiperSlide className={styles.slide} key={year.year}>
								<div className={styles.slideCard}>
									{year.image.localFile && year.image.localFile.childImageSharp ? (
										<GatsbyImage
											image={year.image.localFile.childImageSharp.gatsbyImageData}
											alt={year.title}
											className={styles.image}
											objectFit="contain"
										/>
									) : ''}
									<h3 className={styles.heading}>{year.title}</h3>
									<Wysiwyg content={year.content} className={styles.content} />
								</div>
							</SwiperSlide>
						)
					})}
				</Swiper>
			</div>
		</Section>
	)
}

export default History
