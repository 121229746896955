import React from 'react'

import Section from '@components/Content/Section/Section'
import Wysiwyg from '@components/Layout/Wysiwyg'

import { AboutPageCoreValuesProps } from '@contracts/page'

import * as styles from './CoreValues.module.scss'

interface Props {
	data: AboutPageCoreValuesProps
}

const CoreValues = (props: Props) => {
	const { data } = props

	return (
		<Section className={styles.section} noPaddingBottom>
			<h2 className={styles.title}>{data.title}</h2>

			<ul className={styles.grid}>
				{data.values.map((value, i) => {
					return (
						<li className={styles.item} key={`value=${i}`}>
							<h3 className={styles.name}>{value.name}</h3>

							<Wysiwyg content={value.subtext} className={styles.content} />
						</li>
					)
				})}
			</ul>
		</Section>
	)
}

export default CoreValues
