import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import Section from '@components/Content/Section/Section'
import Wysiwyg from '@components/Layout/Wysiwyg'
import WordpressImage from '@components/Wordpress/Image/WordpressImage'

import { CurrentVacanciesProps, JoinOurTeamProps } from '@contracts/about'

import * as styles from './OurTeam.module.scss'
import Vacancies from './Vacancies'

interface Props {
	data: {
		joinOurTeam: JoinOurTeamProps
		currentVacancies: CurrentVacanciesProps
	}
}

const OurTeam = (props: Props) => {
	const { joinOurTeam, currentVacancies } = props.data

	return (
		<>
			<div>
				{joinOurTeam.teamImage.localFile && (
					<GatsbyImage
						image={joinOurTeam.teamImage.localFile.childImageSharp.gatsbyImageData}
						alt=""
						className={styles.teamImage}
					/>
				)}
			</div>

			<Section className={styles.section}>
				<h2 className={styles.title}>{joinOurTeam.title}</h2>

				<Wysiwyg className={styles.subtext} content={joinOurTeam.subtext} />

				<ul className={styles.grid}>
					{joinOurTeam.perks.map((perk, i) => {
						return (
							<li className={styles.item} key={`${i}-${perk.name}`}>
								<WordpressImage {...perk.icon} className={styles.image} altText={perk.name} />

								<span className={styles.perk}>{perk.name}</span>
							</li>
						)
					})}
				</ul>

				<Vacancies data={currentVacancies} />
			</Section>
		</>
	)
}

export default OurTeam
