import React from 'react'

import Feature from '@components/Content/Feature/Feature'
import Section from '@components/Content/Section/Section'
import FullSlider from '@components/Content/Slider/FullSlider'
import GatsbyHead from '@components/Layout/GatsbyHead'
import Layout from '@components/Layout/Layout'
import SEO from '@components/Layout/SEO'
import LendersTrusted from '@components/Lenders/LendersTrusted'
import AboutFeature from '@components/Pages/AboutUs/AboutFeature'
import CoreValues from '@components/Pages/AboutUs/CoreValues'
import History from '@components/Pages/AboutUs/History'
import OurTeam from '@components/Pages/AboutUs/OurTeam'
import TestimonialsSection from '@components/Testimonials/TestimonialsSection'

import { AboutPageQueryProps } from '@contracts/page'

import * as styles from './AboutPage.module.scss'

const AboutPage = (props: AboutPageQueryProps) => {
	const { data } = props.pageContext

	const { aboutPage } = data.template
	const { feature, coreValues, joinOurTeam, currentVacancies, fullSlider, history, subFeature } = aboutPage

	const featuredImage =
		feature &&
		feature.image &&
		feature.image.localFile &&
		feature.image.localFile.childImageSharp &&
		feature.image.localFile.childImageSharp.gatsbyImageData
			? feature.image.localFile.childImageSharp.gatsbyImageData
			: null
	const featuredImageBkp = feature && feature.image && feature.image.mediaItemUrl ? feature.image.mediaItemUrl : ''

	const imageAlt =
		(featuredImage || featuredImageBkp) && feature.image.altText ? feature.image.altText : feature.title

	return (
		<Layout>
			<Section noPaddingBottom>
				<Feature
					image={featuredImage}
					imageBkp={featuredImageBkp}
					imageAlt={imageAlt}
					title={feature.title}
					className={styles.feature}
					eager={true}
				/>
			</Section>

			<Section noPaddingBottom>
				<AboutFeature data={subFeature} />
			</Section>

			<CoreValues data={coreValues} />

			<History data={history} />

			<LendersTrusted />

			<TestimonialsSection title="What our customers say" />

			<OurTeam data={{ joinOurTeam, currentVacancies }} />

			<FullSlider data={fullSlider} />
		</Layout>
	)
}

export function Head(props: AboutPageQueryProps) {
	return (
		<>
			<GatsbyHead />
			<SEO data={props.pageContext.data.seo} />
		</>
	)
}

export default AboutPage
