import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Button from '@components/Content/Button/Button'
import Section from '@components/Content/Section/Section'
import Wysiwyg from '@components/Layout/Wysiwyg'

import { TrustedLendersProps } from '@contracts/lenders'

import * as styles from './LendersTrusted.module.scss'

const LendersTrusted = () => {
	const data: TrustedLendersProps = useStaticQuery(graphql`
		query TrustedLendersQuery {
			wp {
				lendersSettings {
					lendersOptions {
						trustedLenders {
							title
							subtext
							lenders {
								lender {
									... on WpLender {
										id
										featuredImage {
											node {
												localFile {
													childImageSharp {
														gatsbyImageData(height: 150, placeholder: BLURRED)
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	const { trustedLenders } = data.wp.lendersSettings.lendersOptions

	return (
		<Section className={styles.section}>
			<h2 className={styles.title}>{trustedLenders.title}</h2>

			<Wysiwyg className={styles.subtext} content={trustedLenders.subtext} />

			<ul className={styles.grid}>
				{trustedLenders.lenders.map(({ lender }) => {
					if(lender.featuredImage.node.localFile) {
						return (
							<li key={lender.id} className={styles.lender}>
								<GatsbyImage
									image={lender.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
									alt=""
								/>
							</li>
						)
					} else {
						return null
					}
				})}
			</ul>

			<Button label="View all lenders" uri="/our-lenders" />
		</Section>
	)
}

export default LendersTrusted
