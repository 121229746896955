// extracted by mini-css-extract-plugin
export var container = "History-module--container--bd1dc";
export var content = "History-module--content--5a2b5";
export var heading = "History-module--heading--678bc";
export var image = "History-module--image--6b4a0";
export var mainContent = "History-module--main-content--9228d";
export var motorfinityLogo = "History-module--motorfinity-logo--2e030";
export var navNext = "History-module--nav-next--6162f";
export var navPrev = "History-module--nav-prev--1eab1";
export var navigationContainer = "History-module--navigation-container--b46ee";
export var pagination = "History-module--pagination--f5e41";
export var paginationContainer = "History-module--pagination-container--d784d";
export var section = "History-module--section--478f4";
export var slide = "History-module--slide--28142";
export var slideCard = "History-module--slide-card--3095a";
export var slideContent = "History-module--slide-content--e408a";
export var slider = "History-module--slider--96976";
export var title = "History-module--title--21c2b";