import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import Wysiwyg from '@components/Layout/Wysiwyg'

import { AboutPageTemplateProps } from '@contracts/page'

import * as styles from './AboutFeature.module.scss'

type Props = {
	data: AboutPageTemplateProps['subFeature']
}

const AboutFeature = (props: Props) => {
	return (
		<div className={styles.feature}>
			<div className={styles.left}>
				{props.data.image.localFile && (
					<GatsbyImage
						image={props.data.image.localFile.childImageSharp.gatsbyImageData}
						alt={props.data.image.altText}
						className={styles.image}
					/>
				)}
			</div>
			<div className={styles.right}>
				<Wysiwyg content={props.data.content} className={styles.content} />
				<span className={styles.tagline}>{props.data.tagline}</span>
			</div>
		</div>
	)
}

export default AboutFeature
