import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { GatsbyImage } from 'gatsby-plugin-image'

import { FullSliderItemProps } from '@contracts/content'

import * as styles from './FullSlider.module.scss'

type Props = {
	data: {
		slides: FullSliderItemProps[]
		fieldGroupName: string
	}
}

const FullSlider = (props: Props) => {
	const { slides, fieldGroupName } = props.data

	return (
		<div className={styles.section}>
			<Swiper
				slidesPerView={'auto'}
				loop={true}
				loopedSlides={slides.length}
				centeredSlides={true}
				spaceBetween={16}
				autoplay={true}
				className={styles.slider}
			>
				{slides.map((slide, i) => {
					if (slide.image.localFile) {
						return (
							<SwiperSlide
								key={`${fieldGroupName}-${i}`}
								className={styles.slide}
							>
								<GatsbyImage
									image={
										slide.image.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={slide.image.altText}
								/>
							</SwiperSlide>
						)
					} else {
						return null
					}
				})}
			</Swiper>
		</div>
	)
}

export default FullSlider
